.react-tel-input .special-label {
  background-color: #ecfbf7 !important;
}

@media (max-width: 600px) {
  .react-tel-input .special-label {
    background-color: #f7f7f7 !important;
  }
}

.react-tel-input .selected-flag:focus .arrow {
  border-top-color: #39c7a5 !important;
}

.react-tel-input .form-control:focus {
  border-color: #39c7a5;
  box-shadow: 0 0 0 1px #39c7a5;
}