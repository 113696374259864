@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

body {
  background-color: #f7f7fc;
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

::-webkit-scrollbar {
  width: 0.4rem;
  background: #d2fffa;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d2fffa;
  border-radius: 0.6rem;
  z-index: -99;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #298b7e;
  border-radius: 1rem;
}

.android-button {
  margin-left: 145px;
}

.css-1mjvbk-MuiGrid-root>.MuiGrid-item{
  padding-left: 0 !important;
}

@media (max-width: 600px) {
  .android-button {
    margin-left: 15px;
  }
}
